import { createStore } from "vuex";
import home from "./modules/Home";

export default createStore<State>({
    modules: {
        home
    }
});

export interface State {
}