<template>
  <div class="home">
    <MenuBar />
  </div>
  <Button icon="pi pi-plus" class="p-button-rounded" @click="add()" />
  <DataTable :value="applications" :paginator="true" :rows="20">
    <Column field="naam" header="Naam" :sortable="true"></Column>
    <Column field="applicationInsights" header="Application Insights"></Column>
    <Column field="freshdesk" header="Freshdesk"></Column>
    <Column headerStyle="width: 4rem; text-align: center" bodyStyle="text-align: center; overflow: visible">
      <template #body="details">
        <Button type="button" icon="pi pi-cog" @click="update(details.data.id)" class="p-button-sm"></Button>
      </template>
  </Column>
  </DataTable>
</template>
<script>
// @ is an alias to /src
import MenuBar from '@/components/MenuBar.vue';

export default {
  name: 'Home',
  components: {
    MenuBar
  },
  data() {
    return {
      applications: [
        {
          id: 1,
          naam: 'Momento Plus',
          applicationInsights: true,
          freshdesk: false
        },
        {
          id: 2,
          naam: 'Groeimeter',
          applicationInsights: false,
          freshdesk: true
        },
        {
          id: 3,
          naam: 'Burgers Zoo',
          applicationInsights: true,
          freshdesk: true
        }
      ]
    }
  },
  methods: {
    add() {
      this.$router.push({ name: 'AddApplicatie' });
    },
    update(id) {
      this.$router.push({ name: 'UpdateApplicatie', params: { id: id } });
    }
  }
}
</script>
