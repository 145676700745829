
import { defineComponent } from 'vue'
import { useRoute } from "vue-router";
import MenuBar from "@/components/MenuBar.vue";
export default defineComponent({
  name: "ApplicatieDetails",  
  components: {
    MenuBar,
  },
  data() {
    return {
      
    };
  },
  mounted() {
    const route = useRoute();
    const id = route.params.id;
    console.log(id);
  },
});
