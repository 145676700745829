import { createApp } from 'vue'
import { createStore } from 'vuex'
import App from './App.vue'
import PrimeVue from 'primevue/config'
import router from './router'
import 'primevue/resources/themes/saga-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';

import devtools from '@vue/devtools'

import Button from 'primevue/button'
import Menubar from 'primevue/menubar';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import ColumnGroup from 'primevue/columngroup';
import InputText from 'primevue/inputtext';
import Chart from 'primevue/chart';
import Card from 'primevue/card';
import Message from 'primevue/message';
import store from './store/index';

const app = createApp(App).use(router);
app.mount('#app')
app.use(PrimeVue)
app.use(store);
app.component('Button', Button)
app.component('Menubar', Menubar)
app.component('DataTable', DataTable)
app.component('Column', Column)
app.component('ColumnGroup', ColumnGroup)
app.component('InputText', InputText)
app.component('Chart', Chart)
app.component('Card', Card)
app.component('Message', Message)

if (process.env.NODE_ENV === 'development') {
    devtools.connect('localhost', 8080)
}
