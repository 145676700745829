import { Module } from "vuex";
import { State } from "../index";

const applications: Module<Applications, State> = {
    namespaced: true,
    state: () =>
    ({
        applications: []
    })
};

export default applications;

interface Application {
    id: number;
    naam: string;
    devScore: number;
    uxScore: number;
    chart: {
        datasets: {
            label: string;
            data: number[];
            fill: boolean;
            borderColor: string;
        }[];
    };
}

interface Applications {
    applications: Application[];
}