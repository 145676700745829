<template>
  <div class="applicatieForm">
    <MenuBar />
  </div>
  <Card>
    <template #title>Applicatie</template>
    <template #content>
      <div class="card">
        <div class="p-formgroup-inline">
          <div class="p-field p-fluid p-col">
            <label for="naam">Naam</label>
            <InputText id="naam" type="text" v-model="model.naam" />
          </div>
        </div>
        <h4>Application Insights</h4>
        <div class="p-formgroup-inline">
          <div class="p-field p-fluid p-col">
            <label for="clientId">Client id</label>
            <InputText id="clientId" type="text" v-model="model.clientId" />
          </div>
          <div class="p-field p-fluid p-col">
            <label for="appId">App id</label>
            <InputText id="appId" type="text" v-model="model.appId" />
          </div>
          <div class="p-field p-fluid p-col">
            <label for="clientSecret">Client secret</label>
            <InputText id="clientSecret" type="text" v-model="model.clientSecret" />
          </div>
        </div>
        <h4>Freshdesk</h4>
        <div class="p-formgroup-inline">
          <div class="p-field p-fluid p-col">
            <label for="freshdeskAppNaam">Naam</label>
            <InputText id="freshdeskAppNaam" type="text" v-model="model.freshdeskAppNaam" />
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <Message severity="error" v-if="error.length > 2" :closable="false">Naam verplicht</Message>
      <Button icon="pi pi-check" label="Opslaan" @click="opslaan()" />
    </template>
  </Card>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import { useRoute } from "vue-router";
import MenuBar from "@/components/MenuBar.vue";

export default defineComponent({
  name: "ApplicatieForm",
  components: {
    MenuBar,
  },
  data() {
    return {
      error: '',
      model: {
        id: 0,
        naam: "",
        clientId: "",
        appId: "",
        clientSecret: "",
        freshdeskAppNaam: ""
      },
    };
  },

  mounted() {
    const route = useRoute();
    const id = route.params.id;
    console.log(id);
    if (id) {
      
      this.model = {
        id: 1,
        naam: "Momento Plus",
        clientId: "123",
        appId: "321",
        clientSecret: "147",
        freshdeskAppNaam: "Momento Plus"
      };
    }
  },
  methods: {
    opslaan() {
      console.log("saving");
      if(!this.model.naam && this.model.naam.length < 2) {
        this.error = 'Naam is verplicht';
        return;
      }

      if (this.model.id === 0) {
        //TODO add
      } else {
        //TODO update
      }
      this.$router.push({ name: "Applicaties" });
    },
  },
});
</script>
<style scoped>
.p-field {
  min-width: 200px;
}
</style>