<template>
  <div class="details">
    <MenuBar />
    <h1>This is an details page</h1>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import { useRoute } from "vue-router";
import MenuBar from "@/components/MenuBar.vue";
export default defineComponent({
  name: "ApplicatieDetails",  
  components: {
    MenuBar,
  },
  data() {
    return {
      
    };
  },
  mounted() {
    const route = useRoute();
    const id = route.params.id;
    console.log(id);
  },
});
</script>