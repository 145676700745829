import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from '../views/Home.vue'
import ApplicatieForm from '../views/ApplicatieForm.vue'
import NotFound from '../views/NotFound.vue'
import ApplicatieDetails from '../views/ApplicatieDetails.vue'
import Applicaties from '../views/Applicaties.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/applicaties',
    name: 'Applicaties',
    component: Applicaties
  },
  {
    path: '/applicaties/add',
    name: 'AddApplicatie',
    component: ApplicatieForm
  },
  {
    path: '/applicaties/update/:id',
    name: 'UpdateApplicatie',
    component: ApplicatieForm
  },
  {
    path: '/details/:id',
    name: 'ApplicatieDetails',
    component: ApplicatieDetails
  },
  {
    path: "/:catchAll(.*)",
    name: 'NotFound',
    component: NotFound
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
