
import { defineComponent } from 'vue'

export default defineComponent({
  // type inference enabled
  data() {
    return {
      items: [
        {
          label: 'Home',
          icon:'pi pi-fw pi-home',
          url: '/'
        },
        {
          label: 'Applicaties',
          icon:'pi pi-fw pi-cog',
          url: '/applicaties'
        }
      ]
    }
  }
})

