
import { defineComponent } from 'vue'
import { useRoute } from "vue-router";
import MenuBar from "@/components/MenuBar.vue";

export default defineComponent({
  name: "ApplicatieForm",
  components: {
    MenuBar,
  },
  data() {
    return {
      error: '',
      model: {
        id: 0,
        naam: "",
        clientId: "",
        appId: "",
        clientSecret: "",
        freshdeskAppNaam: ""
      },
    };
  },

  mounted() {
    const route = useRoute();
    const id = route.params.id;
    console.log(id);
    if (id) {
      
      this.model = {
        id: 1,
        naam: "Momento Plus",
        clientId: "123",
        appId: "321",
        clientSecret: "147",
        freshdeskAppNaam: "Momento Plus"
      };
    }
  },
  methods: {
    opslaan() {
      console.log("saving");
      if(!this.model.naam && this.model.naam.length < 2) {
        this.error = 'Naam is verplicht';
        return;
      }

      if (this.model.id === 0) {
        //TODO add
      } else {
        //TODO update
      }
      this.$router.push({ name: "Applicaties" });
    },
  },
});
