<template>
  <div>
    <Menubar :model="items">
      <template #start>
        <img alt="logo" src="../assets/infocaster-logo.svg" height="40" class="p-mr-2">
      </template>
      <template #item="{item}">
        <a :href="item.url">{{item.label}}</a>
      </template>
    </Menubar>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  // type inference enabled
  data() {
    return {
      items: [
        {
          label: 'Home',
          icon:'pi pi-fw pi-home',
          url: '/'
        },
        {
          label: 'Applicaties',
          icon:'pi pi-fw pi-cog',
          url: '/applicaties'
        }
      ]
    }
  }
})

</script>
<style scoped>
.p-menubar {
  margin-bottom: 10px;
}
</style>